<template>
  <div class="header-part">
    <!--显示内容-->

    <div class="header-right-box">
      <div v-if="breadcrumb.length > 1">
        <span>{{ breadcrumb[0].name }}</span>
        /
        <span
          ><router-link :to="breadcrumb[1].path">{{
            breadcrumb[1].name
          }}</router-link></span
        >
      </div>
      <div class="" v-else-if="(breadcrumb.length = 1)">
        <router-link :to="breadcrumb[0].path">{{
          breadcrumb[0].name
        }}</router-link>
      </div>
      <div class="header-user-info">
        <div class="header-user-username">
          <el-dropdown @command="handlerCommand">
            <span class="el-dropdown-link">
              {{ userInfo.adminName || '' }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { routes } from "../router/index.js";
export default {
  data() {
    return {
      userInfo: {},
      mrouteName: this.$route.name,
    };
  },
  computed: {
    breadcrumb() {
      let route = routes[0].children;
      let breadcrumb = [];
      route.forEach((item) => {
        if (this.$route.fullPath.includes(item.path)) {
          breadcrumb.push(item);
          item.children &&
            item.children.forEach((i, index) => {
              if (this.$route.fullPath.includes(i.path)) {
                breadcrumb.push(item.children[index]);
              }
            });
        }
      });
      return breadcrumb;
    },
  },
  methods: {
    handlerCommand(commend) {
      if (commend === "logout") {
        this.http.get("/manager/login/out").then((res) => {
          this.$message({
            message: res.message,
            center: true,
            type: "success",
          });
          window.sessionStorage.removeItem("waterUserInfo");
          this.$router.push({
            path: "/login",
          });
        });
      }
    },
  },
  mounted() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem("waterUserInfo"));
  },
};
</script>
<style scoped lang="less">
.header-part {
  height: 100%;
  display: flex;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  .header-right-box {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  }
}
.header-user-info {
  margin-right: 30px;
  cursor: pointer;
}

.header-user-avatar img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  vertical-align: middle;
}
</style>
