import Vue from 'vue'
import App from './App.vue'

import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'
import leftMenu from './layout/left-menu';
import topHeader from './layout/top-header';
import http from '../src/api/service'
import './assets/style/font.css';

Vue.prototype.http = http;
Vue.config.productionTip = false;

Vue.component('leftMenu', leftMenu);
Vue.component('topHeader', topHeader);
Vue.use(ElementUI);
router.beforeEach((to, from, next) => {
	if (to.path === '/login' || to.path === '/new') {
		next();
	} else {
		let waterUserInfo = window.sessionStorage.getItem('waterUserInfo')
		let limit = JSON.parse(window.sessionStorage.getItem("waterUserInfo"))?.routeIds || []
		if (waterUserInfo) {
			switch (to.path) {
				case "/index":
					if (limit.includes("1")) {
						next()
					}
					break
				case "/goods/classification":
					if (limit.includes("6")) {
						next()
					}
					break
				case "/goods/goodslsit":
					if (limit.includes("7")) {
						next()
					}
					break
				case "/goods/fastgoods":
					if (limit.includes("8")) {
						next()
					}
					break
				case "/goods/addgoods":
					if (limit.includes("6")) {
						next()
					}
					break
				case "/order/orderlist":
					if (limit.includes("2")) {
						next()
					}
					break
				case "/order/ordertake":
					if (limit.includes("3")) {
						next()
					}
					break
				case "/order/pre-sales":
					if (limit.includes("4")) {
						next()
					}
					break
				case "/order/orderExport":
					if (limit.includes("5")) {
						next()
					}
					break
				case "/order/addOrder":
					if (limit.includes("2")) {
						next()
					}
					break
				case "/repair/repairlist":
					if (limit.includes("13")) {
						next()
					}
					break
				case "/repair/watermachine":
					if (limit.includes("14")) {
						next()
					}
					break
				case "/repair/repairorderexport":
					if (limit.includes("15")) {
						next()
					}
					break
				case "/repair/addrepair":
					if (limit.includes("13")) {
						next()
					}
					break
				case "/bottle/bottleorder":
					if (limit.includes("9")) {
						next()
					}
					break
				case "/bottle/bottlemanage":
					if (limit.includes("10")) {
						next()
		 		}
					break
				case "/bottle/bottleAdd":
					if (limit.includes("9")) {
						next()
					}
					break
				case "/activity/activityticket":
					if (limit.includes("27")) {
						next()
					}
					break
				case "/activity/activitymanage":
					if (limit.includes("28")) {
						next()
					}
					break
				case "/activity/addActivity":
					if (limit.includes("28")) {
						next()
					}
					break
				case "/activity/associatedGoods":
					if (limit.includes("27")) {
						next()
					}
					break
				case "/activity/ticketLook":
					if (limit.includes("29")) {
						next()
					}
					break
				case "/activity/ticketdetails":
					if (limit.includes("29")) {
						next()
					}
					break
				case "/balance/balancemanage":
					if (limit.includes("11")) {
						next()
					}
					break
				case "/balance/withdrawal":
					if (limit.includes("12")) {
						next()
					}
					break
				case "/balance/record":
					if (limit.includes("11")) {
						next()
					}
					break
				case "/finance/financemanage":
					if (limit.includes("18")) {
						next()
					}
					break
				case "/finance/income":
					if (limit.includes("19")) {
						next()
					}
					break
				case "/message/messagemanage":
					if (limit.includes("16")) {
						next()
					}
					break
				case "/message/sendMessage":
					if (limit.includes("17")) {
						next()
					}
					break
				case "/personnel/character":
					if (limit.includes("21")) {
						next()
					}
					break
				case "/personnel/administrators":
					if (limit.includes("20")) {
						next()
					}
					break
				case "/personnel/customer":
					if (limit.includes("22")) {
						next()
					}
					break
				case "/personnel/site":
					if (limit.includes("22")) {
						next()
					}
					break
				case "/other/advise":
					if (limit.includes("23")) {
						next()
					}
					break
				case "/other/loop":
					if (limit.includes("24")) {
						next()
					}
					break
				case "/other/agreement":
					if (limit.includes("25")) {
						next()
					}
					break
				case "/other/journal":
					if (limit.includes("26")) {
						next()
					}
					break
			}
		} else {
			next({
				path: '/login'
			})
		}
	}
})

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
