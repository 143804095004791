import axios from 'axios'
import router from '../router/index'

import { Message } from 'element-ui'

function getToken() {
  let token = JSON.parse(window.sessionStorage.getItem('waterUserInfo'))?.token || null
  return token
}
const baseURL = ''
// 创建axios实例
let service = axios.create({
  baseURL: baseURL,
  withCredentials: true, // 跨域访问带上cookie
  timeout: 30000, // 请求超时时间
})

// 添加request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers.token = getToken()
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
// 添加respone拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code == 401) {
      router.push({ path: '/login' })
      return Promise.reject(response?.data).catch(() => {})
    } else if (response.data.code == 200) {
      return response.data
    } else {
      Message({
        message: response.data.message,
        center: true,
        type: 'error',
      })
      return Promise.reject(response?.data).catch(() => {})
    }
  },
  (error) => {
    return Promise.reject(error.response).catch(() => {})
  }
)

function get(url, params = {}) {
  return service({
    url: url,
    method: 'get',
    params,
  })
}

// 封装post请求
function post(url, params = {}, ContentType = 'application/json;charset=UTF-8') {
  // 默认配置
  if (ContentType == 'application/x-www-form-urlencoded') {
    const param = new URLSearchParams()
    let keys = Object.keys(params)
    for (let i = 0; i < keys.length; i++) {
      param.append(keys[i], JSON.stringify(params[keys[i]]))
    }
    params = param
  }
  let sendObject = {
    url: url,
    method: 'post',
    headers: {
      'Content-Type': ContentType,
    },
    data: params,
  }
  return service(sendObject)
}

function postFrom(url, params = {}) {
  // 默认配置
  let sendObject = {
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: params,
  }
  return service(sendObject)
}

// 封装put方法 (resfulAPI常用)
function put(url, data = {}) {
  return service({
    url: url,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    data: JSON.stringify(data),
  })
}

// 不要忘记export
export default {
  get,
  post,
  postFrom,
  put,
  baseURL,
}
