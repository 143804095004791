<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
html,
body,
#app {
  height: 100%;
}
.mar-lr-15 {
  margin: 0 15px !important;
}
.mar-l-15 {
  margin-left: 15px !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bdbdbd;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
a {
  text-decoration: none;
}
#app {
  min-width: 1140px;
}
* {
  padding: 0;
  margin: 0;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.clearfix {
  overflow: auto;
  zoom: 1;
}
</style>

