import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 登录
const login = () => import('@/page/login')
// 首页
const index = () => import('@/page/index')
// 布局
const baseView = () => import('@/layout/base-view.vue')
const rightContent = () => import('@/layout/right-content.vue')

// 商品管理
const classification = () => import('@/page/goods/classification')
const goodsLsit = () => import('@/page/goods/goodsLsit')
const fastGoods = () => import('@/page/goods/fastGoods')
const addGoods = () => import('@/page/goods/addGoods')

// 订单管理
const orderList = () => import('@/page/order/orderList')
const addOrder = () => import ('@/page/order/addOrder')
const orderTake = () => import('@/page/order/orderTake')
const preSales = () => import('@/page/order/presales')
const orderExport = () => import('@/page/order/orderExport')

// 清洗维修
const repairList = () => import('@/page/repair/repairList')
const waterMachine = () => import('@/page/repair/waterMachine')
const repairOrderExport = () => import('@/page/repair/orderExport')
const addRepair = () => import('@/page/repair/addRepair')

// 押瓶管理
const bottleManage = () => import('@/page/bottle/bottleManage')
const bottleOrder = () => import('@/page/bottle/bottleOrder')
const bottleAdd = () => import('@/page/bottle/bottleAdd')

// 活动管理
const activityTicket = () => import('@/page/activity/activityTicket')
const activityManage = () => import('@/page/activity/activityManage')
const addActivity = () => import('@/page/activity/addActivity')
const associatedGoods = () => import('@/page/activity/associatedGoods')
const ticketLook = () => import('@/page/activity/ticketLook')
const ticketDetails = () => import('@/page/activity/ticketDetails')

// 余额管理
const balanceManage = () => import('@/page/balance/balanceManage')
const withdrawal = () => import('@/page/balance/withdrawal')
const record = () => import('@/page/balance/record')

// 财务管理
const financeManage = () => import('@/page/finance/financeManage')
const income = () => import('@/page/finance/income')

// 消息管理
const messageManage = () => import('@/page/message/messageManage')
const sendMessage = () => import('@/page/message/sendMessage')

// 人员管理
const character = () => import('@/page/personnel/character')
const administrators = () => import('@/page/personnel/administrators')
const customer = () => import('@/page/personnel/customer')
const site = () => import('@/page/personnel/site')

// 其他管理
const advise = () => import('@/page/other/advise')
const loop = () => import('@/page/other/loop')
const agreement = () => import('@/page/other/agreement')
const journal = () => import('@/page/other/journal')
export const routes = [
  {
    path: '/',
    component: baseView,
    redirect: '/login',
    children: [
      { path: '/new', name: '中转', hidden: true},
      { path: '/index', name: '首页', hidden: "1", component: index, icon: 'el-icon-s-home' },
      {
        path: '/goods',
        name: '商品管理',
        hidden: ["6","7","8"],
        component: rightContent,
        icon: 'el-icon-s-goods',
        children: [
          { path: 'classification', name: '商品分类', hidden: "6", component: classification },
          { path: 'goodslsit', name: '商品列表', hidden: "7", component: goodsLsit },
          { path: 'fastgoods', name: '快捷商品', hidden: "8", component: fastGoods },
          { path: 'addgoods', name: '创建商品', hidden: true, component: addGoods },
        ],
      },
      {
        path: '/order',
        name: '订单管理',
        hidden: ["2","3","4","5"],
        component: rightContent,
        icon: 'el-icon-s-order',
        children: [
          { path: 'orderlist', name: '全部订单', hidden: "2", component: orderList },
          { path: 'ordertake', name: '接单配送', hidden: "3", component: orderTake },
          { path: 'pre-sales', name: '售前管理', hidden: "4", component: preSales },
		  { path: 'orderExport', name: '订单导出', hidden: "5", component: orderExport },
		  { path: 'addOrder', name: '添加订单', hidden: true, component: addOrder },
        ],
      },
      {
        path: '/repair',
        name: '清洗维修',
        hidden: ["13","14","15"],
        component: rightContent,
        icon: 'el-icon-s-tools',
        children: [
          { path: 'repairlist', name: '清洗维修 ', hidden: "13", component: repairList },
          { path: 'watermachine', name: '水机管理', hidden: "14", component: waterMachine },
          { path: 'repairorderexport', name: '维修订单导出', hidden: "15", component: repairOrderExport },
          { path: 'addrepair', name: '创建清洗/维修', hidden: true, component: addRepair },
        ],
      },
      {
        path: '/bottle',
        name: '押瓶管理',
        hidden: ["9","10"],
        component: rightContent,
        icon: 'el-icon-milk-tea',
        children: [
          { path: 'bottleorder', name: '押瓶订单', hidden: "9", component: bottleOrder },
          { path: 'bottlemanage', name: '瓶子管理', hidden: "10", component: bottleManage },
          { path: 'bottleAdd', name: '创建押瓶', hidden: true, component: bottleAdd },
        ],
      },
      {
        path: '/activity',
        name: '活动管理',
        hidden: ["27","28","29"],
        component: rightContent,
        icon: 'el-icon-s-ticket',
        children: [

          { path: 'activityticket', name: '活动券管理', hidden: "27", component: activityTicket },
          { path: 'activitymanage', name: '活动管理 ', hidden: "28", component: activityManage },
		  { path: 'addActivity', name: '新增活动 ', hidden: true, component: addActivity },
		  { path: 'associatedGoods', name: '关联商品 ', hidden: true, component: associatedGoods },
		  { path: 'ticketLook', name: '用券记录', hidden: "29", component: ticketLook },
		  { path: 'ticketdetails', name: '活动券详情', hidden: true, component: ticketDetails },
        ],
      },
      {
        path: '/balance',
        name: '余额管理',
        hidden: ["11","12"],
        component: rightContent,
        icon: 'el-icon-s-finance',
        children: [
          { path: 'balancemanage', name: '余额管理 ', hidden: "11", component: balanceManage },
          { path: 'withdrawal', name: '提现管理', hidden: "12", component: withdrawal },
          { path: 'record', name: '充值和消费记录', hidden: true, component: record },
        ],
      },
      {
        path: '/finance',
        name: '财务管理',
        hidden: ["18","19"],
        component: rightContent,
        icon: 'el-icon-s-marketing',
        children: [
          { path: 'financemanage', name: '财务管理 ', hidden: "18", component: financeManage },
          { path: 'income', name: '收入报表', hidden: "19", component: income },
        ],
      },
      {
        path: '/message',
        name: '消息管理',
        hidden: ["16","17"],
        component: rightContent,
        icon: 'el-icon-chat-dot-round',
        children: [
          { path: 'messagemanage', name: '消息管理 ', hidden: "17", component: messageManage },
          { path: 'sendMessage', name: '发送消息', hidden: "16", component: sendMessage },
        ],
      },
      {
        path: '/personnel',
        name: '人员管理',
        hidden: ["20","21","22"],
        component: rightContent,
        icon: 'el-icon-user-solid',
        children: [
          { path: 'character', name: '角色管理', hidden: "21", component: character },
          { path: 'administrators', name: '管理员管理', hidden: "20", component: administrators },
          { path: 'customer', name: '客户管理', hidden: "22", component: customer },
          { path: 'site', name: '地址管理', hidden: true, component: site },
        ],
      },
      {
        path: '/other',
        name: '其他管理',
        hidden: ["23","24","25","26"],
        component: rightContent,
        icon: 'el-icon-more',
        children: [
          { path: 'advise', name: '投诉建议', hidden: "23", component: advise },
          { path: 'loop', name: '轮播图', hidden: "24", component: loop },
          { path: 'agreement', name: '服务协议', hidden: "25", component: agreement },
          { path: 'journal', name: '操作日志', hidden: "26", component: journal },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: '登录',
    component: login,
	beforeEnter: (to, from, next) => {
		document.title = '云南卡洛斯贸易'; // 设置页面标题
		next();
	}
  },
]

const router = new VueRouter({
  routes,
})


export default router
