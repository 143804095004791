<template>
  <div>
    <div class="top-logo">云雾洱山泉水后台系统V1.0</div>
    <el-menu
      :default-active="$route.path"
      :unique-opened="true"
      class="el-menu-vertical"
      background-color="#001529"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <!--                    遍历菜单内容-->
      <!--                    有两种：第一种没子菜单，另一种有子菜单-->

      <template v-for="(item, index) in menuList">
        <router-link
          :to="item.path"
          v-if="!item.children && !returnHidden(item.hidden)"
          :key="index"
        >
          <el-menu-item :index="item.path">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </router-link>
        <el-submenu
          v-if="item.children && !returnHidden(item.hidden)"
          :key="index"
          :index="item.path"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </template>
          <router-link
            :to="item.path + '/' + subItem.path"
            v-for="(subItem, subIndex) in item.children"
            :key="subIndex"
          >
            <el-menu-item
              :index="item.path + '/' + subItem.path"
              v-if="!returnHidden(subItem.hidden)"
              class="child-padding"
            >
              <i :class="subItem.icon"></i>
              <span slot="title" v-text="subItem.name"></span>
            </el-menu-item>
          </router-link>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>
<script>
import { routes } from "../router";
export default {
  data() {
    return {
      menuList: [],
      userInfo: [
      ],
    };
  },
  methods: {
    returnHidden(status) {
      if (typeof status == "boolean") {
        return status;
      } else if (typeof status == "string") {
        return !this.userInfo.includes(status);
      } else {
        for (let i = 0; i < status.length; i++) {
          if (this.userInfo.includes(status[i])) {
            return false;
          }
        }
        return true;
      }
    },
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem("waterUserInfo"))?.routeIds || [];
  },
  mounted() {
    let menuList = routes[0];
    this.menuList = menuList.children;
    //console.log(this.menuList);
  },
};
</script>
<style scoped>
.top-logo {
  height: 64px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #fff;
  font-size: 16px;
  flex-shrink: 0;
  text-align: center;
}
.el-menu-vertical a {
  text-decoration: none;
}

.child-padding {
  padding: 0px;
}

/deep/.el-menu .el-menu-item.is-active {
  color: #ffffff !important;
  background: #1890ff !important;
}

.el-submenu /deep/.el-submenu__title {
  width: 200px;
}

/* 	.el-submenu /deep/.el-submenu__title:hover {
		color: #2E95FB !important;
		background: linear-gradient(270deg, #F2F7FC 0%, #FEFEFE 100%) !important;
	} */

::v-deep .el-menu-item {
  width: 200px;
  height: 42px;
  line-height: 42px;
}

::v-deep .el-submenu__title {
  height: 42px;
  line-height: 42px;
}
</style>
